<template>
  <v-sheet class="transparent page-sheet">
    <v-row justify="end">
      <v-col cols="3">
        <v-autocomplete v-model="type" :items="['wsrefresh','buy','prebuy','failed']" clearable></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" clearable></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="idx"
      :search="search"
      sort-by="created"
      sort-desc
      class="transparent table-custom"
      :footer-props="foot_props"
      :no-data-text="$t('label.nodata')"
    ></v-data-table>
  </v-sheet>
</template>

<script>
import commonmixin from "@/mixins/commonlist.js";
const apiBase = "v1/cms/licenseslog";

export default {
  name: "LicensesLog",
  mixins: [commonmixin],
  computed: {},
  data() {
    return {
      item: {},
      type: null,
      headers: [
        {
          text: this.$t("fields.created"),
          value: "created",
        },
        {
          text: this.$t("fields.license"),
          value: "license",
        },
        {
          text: this.$t("fields.wsid"),
          value: "wsid",
        },
        {
          text: this.$t("fields.type"),
          value: "type",
          filter: (i) => {
            if (!this.type) return true;
            return this.type == i;
          },
        },
      ],
    };
  },
  methods: {
    loadData() {
      this.$api
        .apiGetRequest(apiBase, {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
        })
        .then((response) => {
          if (response.content) this.items = [...response.content].sort();
          this.totalItems = response.totalElements;
        });
    },
  },
  mounted() {
    this.$api.apiGetRequest(apiBase).then((response) => {
      this.items = [...response];
    });
  },
};
</script>

<style>
</style>